import moment from "moment";

export default {
  data () {
    return {
      orderNo: '',
      fromStationCode: '',
      toStationCode: '',
      time: '',
      type: 'reserve'
    }
  },
  components: {},
  created () {
    document.documentElement.scrollTop = 0;
    this.orderNo = this.$route.query.orderNo
    this.fromStationCode = this.$route.query.fromStationCode
    this.toStationCode = this.$route.query.toStationCode
    this.time = this.$route.query.time
    this.type = this.$route.query.type
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    toHome() {
      this.$router.push({
        name: 'distributor-train-front-home'
      })
    },
    checkDetail() {
      if (this.type === 'booking') {
        this.$router.push({
          name: 'distributor-train-back-train-contend-detail',
          query: {
            orderNo: this.orderNo
          }
        })
      } else {
        this.$router.push({
          name: 'distributor-train-back-train-order-detail',
          query: {
            orderNo: this.orderNo
          }
        })
      }
    },
    back() {
      let thisTime = moment(this.time).valueOf()
      let tomorrow = moment(thisTime + 24 * 3600 * 1000).format('YYYY-MM-DD')
      this.$router.push({
        name: 'distributor-train-front-search',
        query: {
          fromStationCode: this.toStationCode,
          toStationCode: this.fromStationCode,
          time: tomorrow
        }
      })
    }
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
